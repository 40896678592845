import React from "react";
import PratosFromDb from "../../utils/pratos";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import "react-tabs/style/react-tabs.css";

class Admin extends React.Component {
	state = {
		pratos: [],
		bebidas: [],
		brunch: [],
		coffee: [],
		sobremesas: [],
		entradas: [],
		ovosTostas: [],
		panquecas: [],
		sandwishs: [],
		smootjelas: [],
		tjelas: [],
	};

	componentDidMount() {
		const pratosFromDb = new PratosFromDb();
		pratosFromDb.getAll().then((response) => {
			console.log(response);
			this.setState({
				pratos: response.data,
                bebidas: response.data.filter((pratos) => pratos.type.includes("bebidas")),
				brunch: response.data.filter((pratos) => pratos.type.includes("brunch")),
				coffee: response.data.filter((pratos) => pratos.type.includes("coffee")),
				sobremesas: response.data.filter((pratos) => pratos.type.includes("sobremesas")),
				entradas: response.data.filter((pratos) => pratos.type.includes("entradas")),
				ovosTostas: response.data.filter((pratos) => pratos.type.includes("ovosTostas")),
				panquecas: response.data.filter((pratos) => pratos.type.includes("panquecas")),
				sandwishs: response.data.filter((pratos) => pratos.type.includes("sandwishs")),
				smootjelas: response.data.filter((pratos) => pratos.type.includes("smootjelas")),
				tjelas: response.data.filter((pratos) => pratos.type.includes("tjelas")),
            });
		});
	}

	handleFormSubmit = (event) => {
		event.preventDefault();
		const pratosFromDb = new PratosFromDb();

		pratosFromDb.updateMenu(this.state).then((response) => {
			toast(`Profile Updated!`, {
				position: "bottom-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});
	};

	handleChange = (event) => {
		let { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<div>
				<div>
					<Tabs>
						<TabList className="label">
							<Tab>Bebidas</Tab>
							<Tab>Brunch</Tab>
							<Tab>Coffee</Tab>
							<Tab>Sobremesas</Tab>
							<Tab>Entradas</Tab>
							<Tab>Ovos e Tostas</Tab>
							<Tab>Panquecas</Tab>
							<Tab>Sandwishs</Tab>
							<Tab>Smootjelas</Tab>
							<Tab>Tjelas</Tab>
						</TabList>
						<TabPanel className="tab-panel">
							{this.state.bebidas.map((bebidas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">{bebidas.name}</h1>
													<h2 className="f5 mv0">{bebidas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{bebidas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{bebidas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{bebidas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.brunch.map((brunch, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">{brunch.name}</h1>
													<h2 className="f5 mv0">{brunch.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{brunch.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{brunch.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{brunch.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.coffee.map((coffee, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">{coffee.name}</h1>
													<h2 className="f5 mv0">{coffee.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{coffee.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{coffee.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{coffee.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.sobremesas.map((sobremesas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">
														{sobremesas.name}
													</h1>
													<h2 className="f5 mv0">{sobremesas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sobremesas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sobremesas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sobremesas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.entradas.map((entradas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">{entradas.name}</h1>
													<h2 className="f5 mv0">{entradas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{entradas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{entradas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{entradas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.ovosTostas.map((ovosTostas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">
														{ovosTostas.name}
													</h1>
													<h2 className="f5 mv0">{ovosTostas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{ovosTostas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{ovosTostas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{ovosTostas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.panquecas.map((panquecas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">
														{panquecas.name}
													</h1>
													<h2 className="f5 mv0">{panquecas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{panquecas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{panquecas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{panquecas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.sandwishs.map((sandwishs, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">
														{sandwishs.name}
													</h1>
													<h2 className="f5 mv0">{sandwishs.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sandwishs.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sandwishs.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{sandwishs.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.smootjelas.map((smootjelas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">
														{smootjelas.name}
													</h1>
													<h2 className="f5 mv0">{smootjelas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{smootjelas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{smootjelas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{smootjelas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
						<TabPanel className="tab-panel">
							{this.state.tjelas.map((tjelas, index) => {
								return (
									<div key={index} className="dark-gray center w-90 shadow-5 br3 mt6">
										<div className="pa2  bg-gold br3">
											<div className="dt w-100 mt1">
												<div className="dtc">
													<h1 className="f5 f4-ns mv0 black">{tjelas.name}</h1>
													<h2 className="f5 mv0">{tjelas.price}</h2>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{tjelas.description}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{tjelas.addons.name}
													</p>
													<p className="f6 lh-copy measure mt1 mb1 dark-gray">
														{tjelas.addons.price}
													</p>
													<div className="separator"></div>
													<button type="button" className="btn btn-warning">
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</TabPanel>
					</Tabs>
				</div>
			</div>
		);
	}
}


export default Admin; 