import React from 'react';
import OvosTostas from '../OvosTostas/OvosTostas';
import { Link } from 'react-router-dom';
import Logo from './logo.jpg';


const OvosTostasPage = () => (
    <div>
      <header className="bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0">
          <Link to="/menu/brunch" className="pa2 mr4">
              <svg className="w1" data-icon="chevronLeft" viewBox="0 0 32 32" style={{fill: 'black'}}>
                <title>chevronLeft icon</title>
                <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
              </svg>
          </Link>

          <h1 className='center ma0 f3'>Ovos & Tostas</h1>

          <img className="right-0" alt='logo' id='logosm' src={Logo} />
      </header>


      <OvosTostas />
      
    </div>
)

export default OvosTostasPage;