import React, { useState, useEffect } from "react";

function Entradas() {
  const [items, setItems] = useState([]);
  const [menus, setMenus] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }

  async function fetchMenus() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(menu => {
        console.log(menu);
        setMenus(menus => [...menus,
        <span key={menu[0]}>&nbsp; Menu &nbsp; € {menu[2].toFixed(2)}</span>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
    fetchMenus();
  }, []);

  return (
    <div className='mt6'>
    <div className='dark-gray center w-80 w-50-ns shadow-3 br3'>
      <div className='db h4 br3 br--top' id='guacamole'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Guacamole com Tortilhas</h1>
          </div>
            { items[0] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Creme fresco de Abacate com Lima | Tomate | Cebola Roxa | Cebolinho |
          C/Tortilhas &amp; Crocantes de Milho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns shadow-3 br3 mt4'>
      <div className='db h4 br3 br--top' id='gyozas'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Gyozas (4 unidades)</h1>
          </div>
          { items[1] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          <b>Camarão</b>, <b>Frango</b> ou <b>Vegetais</b>
          <br />
          Escolha o molho: Ponzu | Teriyaki | Soja
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns shadow-3 br3 mt4 mb4'>
      <div className='db h4 br3 br--top' id='totopos'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Totopos</h1>
          </div>
           { items[2] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Tortilhas crocantes com Molho de Queijo Cheddar e Bacon
        </p>
      </div>
    </div>
    <div className='dark-gray center w-80 w-50-ns shadow-3 br3 mt4 mb4'>
      <div className='db h4 br3 br--top' id='tacos'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Tacos de Salmão (2 unidades)</h1>
          </div>
           { items[3] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Tacos de Milho | Arroz de Sushi | Salmão | Abacate | Molho de Soja |
          Cebola Roxa | Cebolinho | Sementes de Sésamo
        </p>
      </div>
    </div>
  </div>
  )
};

export default Entradas;
