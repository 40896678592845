import React, { useState, useEffect } from "react";

function Tjelas() {
  
  const [items, setItems] = useState([]);
  const [menus, setMenus] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }

  async function fetchMenus() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(menu => {
        console.log(menu);
        setMenus(menus => [...menus,
        <span key={menu[0]}>&nbsp; Menu &nbsp; € {menu[2].toFixed(2)}</span>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
    fetchMenus();
  }, []);

  return (
  <div>
    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mt6'>
      <div className='db h4 br3 br--top' id='tjela-frango'>
        <div className='ribbon ribbon-top-right br2'>
        { menus[4] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Frango Teriyaki</h1>
          </div>
          { items[5] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Thai | Peito Frango | Molho Teriyaki | Abacate | Manga | Tomate
          | Chips Batata Doce | Cebola Roxa | Sementes de Sésamo | Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3'>
      <div className='db h4 br3 br--top' id='tjela-porco'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[6] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Porco Barbecue</h1>
          </div>
           { items[7] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Thai | Porco Desfiado | Molho Barbecue | Abacaxi | Cebola Roxa |
          Pepino | Tomate | Cebola Crocante | Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3'>
      <div className='db h4 br3 br--top' id='tjela-salmao'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[8] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Poke Salmão</h1>
          </div>
           { items[9] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz de Sushi | Salmão Fresco | Molho Soja | Manga | Abacate | Cebola
          Roxa | Edamame | Cebola Crocante | Sementes de Sésamo | Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3'>
      <div className='db h4 br3 br--top' id='tjela-atum'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[10] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Poke Atum</h1>
          </div>
           { items[11] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz de Sushi | Atum Fresco | Alho Francês | Molho Soja | Edamame |
          Pepino | Abacaxi | Chips Batata Doce | Cebolinho | Sementes de Sésamo
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3'>
      <div className='db h4 br3 br--top' id='tjela-veg'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[12] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>
              TJELA VEG <span>🌱</span>
            </h1>
          </div>
           { items[13] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Grão de Bico Cozido | Abacate | Espinafres | Tomate | Ovo Cozido |
          Cebola Roxa | Cebolinho | Molho Pesto
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3'>
      <div className='db h4 br3 br--top' id='tjela-burger'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[14] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Burger</h1>
          </div>
           { items[15] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Thai | Hambúrguer de Novilho Desconstruido | Molho Queijo
          Cheddar | Bacon | Tomate | Cebolinho | Cebola Roxa | Chips Batata Doce
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb4'>
      <div className='db h4 br3 br--top' id='tjela-nasi'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[16] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Nasi Goreng</h1>
          </div>
           { items[17] }
        </div>
        <h1 className='f6 black'>Camarão</h1>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Frito | Camarão | Mistura de Vegetais (Alho Francês, Couve Roxa,
          Repolho e Cenoura) | Molho Soja | Ovo Estrelado | Cebola Crocante |
          Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb4'>
      <div className='db h4 br3 br--top' id='tjela-nasi-frango'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[18] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>TJELA Nasi Goreng</h1>
          </div>
           { items[19] }
        </div>
        <h1 className='f6 black'>Frango</h1>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Frito | Frango | Mistura de Vegetais (Alho Francês, Couve Roxa,
          Repolho e Cenoura) | Molho Soja | Ovo Estrelado | Cebola Crocante |
          Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb4'>
      <div className='db h4 br3 br--top' id='tjela-nasi-cogumelos'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[20] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>
              TJELA Nasi Goreng <span>🌱</span>
            </h1>
          </div>
           { items[21] }
        </div>
        <h1 className='f6 black'>Cogumelos</h1>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Arroz Frito | Cogumelos | Mistura de Vegetais (Alho Francês, Couve
          Roxa, Repolho e Cenoura) | Molho Soja | Ovo Estrelado | Cebola
          Crocante | Cebolinho
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb4'>
      <div className='db h4 br3 br--top' id='tjela-green'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[22] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>
              TJELA Greeny <span>🌱</span>
            </h1>
          </div>
           { items[23] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Couscous | Cogumelos Salteados | Edamame | Pepino | Abacate | Amêndoa
          Laminada | Molho Vinagrete com Mostarda e Mel | Cebolinho
        </p>
      </div>
    </div>
    </div>
  );
};

export default Tjelas;
