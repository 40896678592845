import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import coffeedential from "./coffeedential.png";
import Logo from "./././logo.jpg";
import user from "../../Assets/user.png";
import users from "../../Assets/users.png";

function FiltroPage() {

  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);

  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";

  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        setItems(items => [...items,
          
          <h2 className="f5 mv0"><img className='ml2 v-mid' alt='' src={user} height='25px' key={element[0]} /> € {element[2].toFixed(2)}</h2>

        ]);
      });
    });
  }

  async function fetchItems2() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        setItems2(items => [...items,
          
          <h2 className="f5 mv0 "><img className='ml2 v-mid' alt='' src={users} height='25px' key={element[0]} /> € {element[2].toFixed(2)}</h2>

        ]);
      });
    });
  }


  useEffect(() => {
    fetchItems();
    fetchItems2();
  }, []);


  return (
    <div>

      <header className="bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0">
        <Link to="/menu/cafe" className="pa2 mr4">
          <svg className="w1" data-icon="chevronLeft" viewBox="0 0 32 32" style={{ fill: 'black' }}>
            <title>chevronLeft icon</title>
            <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
          </svg>
        </Link>

        <img className="center ma0" alt='logo' id='coffedentialLogo' src={coffeedential} />

        <img className="right-0" alt='logo' id='logosm' src={Logo} />
      </header>

      <div className="dark-gray center w-80 shadow-5 br3 mt6">
        <div className="pa2 ph3-ns pb3-ns bg-gold br3">
          <div className="dt w-100 mt1">
            <div className="dtc">
              <h1 className="f4 f4-ns mv0 black">V60</h1>
            </div>
          </div>
          <div className="dt w-100 mt1">
            <h2 className="f5 black" >1 Pessoa</h2>
            <div className="dtc tr black v-mid">
              {items[77]}
            </div>
          </div>
          <div className="dt w-100 mt1 ">
            <h2 className="f5 black" >2 Pessoas</h2>
            <div className="dtc tr black v-mid">
              {items2[78]}
            </div>
          </div>
        </div>
      </div>

      <div className="dark-gray center w-80 shadow-5 br3 mt3">
        <div className="pa2 ph3-ns pb3-ns bg-gold br3">
          <div className="dt w-100 mt1">
            <div className="dtc">
              <h1 className="f4 f4-ns mv0 black">Aeropress</h1>
            </div>
          </div>
          <div className="dt w-100 mt1">
            <h2 className="f5 black" >1 Pessoa</h2>
            <div className="dtc tr black v-mid">
              {items[79]}
            </div>
          </div>
          <div className="dt w-100 mt1 ">
            <h2 className="f5 black" >2 Pessoas</h2>
            <div className="dtc tr black v-mid">
              {items2[80]}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default FiltroPage;
