import axios from "axios";

class PratosFromDb {
	constructor() {
		let service = axios.create({
			baseURL: `http://localhost:5000/pratos`,
			withCredentials: true,
		});
		this.service = service;
	}

	getAll() {
		return this.service.get("/all");
	}

	getMenu(id) {
		return this.service.get(`/${id}`);
	}

	addMenu(newMenu) {
		return this.service.post("/new", {...newMenu,});
	}

    updateMenu(updatePrato) {
		return this.service.put(`update/${updatePrato.id}`, { ...updatePrato });
	}
	
}

export default PratosFromDb;
