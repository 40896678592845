import React from 'react';
import Tjelas from '../Tjelas/Tjelas';
import { Link } from 'react-router-dom';
import Logo from './logo.jpg';
import bowl from '../../Assets/bowl1.png';
import cup from '../../Assets/coffee-cup.png';
import glass from '../../Assets/cocktail.png';



const TjelasPage = () => (
		<div>
			<header className="bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0">
	  			<Link to="/menu" className="pa2 mr4">
			        <svg className="w1" data-icon="chevronLeft" viewBox="0 0 32 32" style={{fill: 'black'}}>
			          <title>chevronLeft icon</title>
			          <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
			        </svg>
			    </Link>

			    <h1 className='center ma0'>TJELAS</h1>

			    <img className="right-0" alt='logo' id='logosm'  src={Logo} />
			</header>
			
			<Tjelas />

			{/*<div className="flex items-center justify-center shadow-5 pa3 pl1 bg-lightest-blue black mt3 mb4">
		    	<span className="f3">
		    	Menu  = &nbsp;
		    	</span>
		    	<img alt='' src='https://image.flaticon.com/icons/svg/856/856178.svg' height='35px' style={{verticalAlign: 'middle'}}/>
		    	&nbsp; + 
		    	<img alt='' src='https://image.flaticon.com/icons/svg/865/865374.svg' height='35px' style={{verticalAlign: 'middle'}}/>
		    	+&nbsp; 
		    	<img alt='' src='https://image.flaticon.com/icons/svg/751/751621.svg' height='25px' style={{verticalAlign: 'middle'}}/>
		    </div>*/}

			<div className="items-center justify-center pa2 ph3 tc br2 bg-lightest-blue black mt3 mb4">
				<span className="f3">
				Menu  = &nbsp;
				</span>
				<img src={bowl} height='35px' style={{verticalAlign: 'middle'}}/>
				&nbsp; + 
				<img src={glass} height='35px' style={{verticalAlign: 'middle'}}/>
				+&nbsp; 
				<img src={cup} height='25px' style={{verticalAlign: 'middle'}}/>
				<h2 class="fw2 f6 lh-copy mt0 mb3">
				Segunda a Sexta ao Almoço
				</h2>
			</div>
    	</div>
)

export default TjelasPage;