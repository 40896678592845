import React from 'react';
import Smootjelas from '../Smootjelas/Smootjelas';
import { Link } from 'react-router-dom';
import Logo from './logo.jpg';

const SmootjelasPage = () => (
    <div>
      <header className="bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0">
          <Link to="/menu" className="pa2 mr4">
              <svg className="w1" data-icon="chevronLeft" viewBox="0 0 32 32" style={{fill: 'black'}}>
                <title>chevronLeft icon</title>
                <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
              </svg>
          </Link>

          <h1 className='center ma0'>Smootjelas</h1>

          <img className="right-0" alt='logo' id='logosm'  src={Logo} />
      </header>


      <Smootjelas />
      
      <div className="flex items-center justify-center shadow-5 pa3 pl1 bg-lightest-blue black mt4 mb5">
            <h1 className="ml3 mv0 f3" >
            Toppings Smootjelas &nbsp;
            <br/>
            <p className='f6 fw5'>
              Granola Caseira | Fruta Fresca | Lascas de Côco | Manteiga de Amendoim ou Mel
            </p>   
            </h1>
          <div className='db'>
          </div>
        </div>
      </div>
)

export default SmootjelasPage;