import React, { useState, useEffect } from "react";

function OvosTostas() {

const [items, setItems] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt6'>
        <div className='db h4 br3 br--top' id='real'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>The Real Tosta de Abacate</h1>
            </div>
            { items[30] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Abacate | Ovo Escalfado | Mix de Sementes | Cebolinho
            | Amêndoa Laminada | Pesto Caseiro
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Com Bacon</h2>
            <div className='dtc tr black v-mid'>
              <h2 className='f5 mv0'>
                <img
                  className='ml2 v-mid'
                  alt=''
                  src='https://image.flaticon.com/icons/svg/2102/2102243.svg'
                  height='35px'
                />
                + € 1,00
              </h2>
            </div>
          </div>
          <div className='dt w-100 mt1 '>
            <h2 className='f6 black'>Com Salmão Fumado</h2>
            <div className='dtc tr black v-mid'>
              <h2 className='f5 mv0 '>
                <img
                  className='ml2 v-mid nl2'
                  alt=''
                  src='https://image.flaticon.com/icons/png/512/1460/1460090.png'
                  height='35px'
                />
                + € 2,00
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='salmon'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Crazy Salmon Eggs</h1>
            </div>
            { items[31] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Ovo Mexido | Salmão Fumado | Mix de Sementes | Pepino
            | Mostarda e Mel | Cebolinho
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Com Abacate</h2>
            <div className='dtc tr black v-mid'>
              <h2 className='f5 mv0'>
                <img
                  className='ml2 v-mid'
                  alt=''
                  src='https://image.flaticon.com/icons/svg/2088/2088828.svg'
                  height='35px'
                />
                + € 2,00
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='bacon-eggs'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Dirty Bacon Eggs</h1>
            </div>
            { items[32] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Ovo Mexido | Queijo Cheddar | Bacon | Pesto Caseiro |
            Mix de Sementes
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Com Abacate</h2>
            <div className='dtc tr black v-mid'>
              <h2 className='f5 mv0'>
                <img
                  className='ml2 v-mid'
                  alt=''
                  src='https://image.flaticon.com/icons/svg/2088/2088828.svg'
                  height='35px'
                />
                + € 2,00
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='bennedictjela'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Eggs Bennedictjela</h1>
            </div>
            { items[33] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Abacate | Ovo Escalfado | <strong>Bacon</strong> |
            Molho Holandês | Cebola Frita | Cebolinho
          </p>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Eggs Royale</h1>
            </div>
            { items[34] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Abacate | Ovo Escalfado | <strong>Salmão</strong> |
            Molho Holandês | Cebola Frita | Cebolinho
          </p>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='florentine-eggs'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Oh! My Eggs Florentine</h1>
            </div>
            { items[35] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Cogumelos Salteados | Espinafres | Ovo Escalfado |
            Molho Holandês | Mix de Sementes | Cebolinho
          </p>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4 mb5'>
        <div className='db h4 br3 br--top' id='mistica'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Tosta Mística</h1>
            </div>
            { items[36] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Pão Alentejano | Bacon | Queijo Cheddar | Pesto Caseiro
          </p>
        </div>
      </div>
    </div>
  );
};

export default OvosTostas;
