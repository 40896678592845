import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import coffeedential from "./coffeedential.png";
import Logo from "./././logo.jpg";

function CoffeedentialPage() {

  const [items, setItems] = useState([]);

  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";

  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        setItems(items => [...items,
        <div className='dark-gray center w-90 shadow-5 br3' key={element[0]}>
          <div className='bg-gold br3'>
            <div className='dt w-100 mt1 pa2 pa2'>
              <div className='dtc'>
                <h1 className='f5 f4-ns mv0 black'>{element[1]}</h1>
              </div>
              <div className='dtc tr black' key={element[0]}>
                <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
              </div>
            </div>
          </div>
        </div>
        ]);
      });
    });
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <header className='bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0'>
        <Link to='/menu/cafe' className='pa2 mr4'>
          <svg
            className='w1'
            data-icon='chevronLeft'
            viewBox='0 0 32 32'
            style={{ fill: "black" }}>
            <title>chevronLeft icon</title>
            <path d='M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z' />
          </svg>
        </Link>

        <img
          className='center ma0'
          alt='logo'
          id='coffedentialLogo'
          src={coffeedential}
        />

        <img className='right-0' alt='logo' id='logosm' src={Logo} />
      </header>
      <div className="mt6"></div>

      {items[65] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[65]}
        </Suspense>
      )}
      {items[66] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[66]}
        </Suspense>
      )}
      {items[67] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[67]}
        </Suspense>
      )}
      {items[68] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[68]}
        </Suspense>
      )}
      {items[69] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[69]}
        </Suspense>
      )}
      {items[70] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[70]}
        </Suspense>
      )}
      {items[71] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[71]}
        </Suspense>
      )}
      {items[72] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[72]}
        </Suspense>
      )}
      {items[73] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[73]}
        </Suspense>
      )}
      {items[74] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[74]}
        </Suspense>
      )}
      {items[75] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[75]}
        </Suspense>
      )}
      {items[76] && (
        <Suspense fallback={<div>Loading...</div>}>
          {items[76]}
        </Suspense>
      )}

      <div class="mb3"></div>

      <div className='shadow-5 pa1 bg-lightest-blue black mb4 w-100'>
        <h3
          className='m0 text-center'
          style={{ margin: "1rem 0 0.5rem 0", textAlign: "center" }}>
          Extras
        </h3>
        <p className=' fw6' style={{ margin: "0 0 1rem 0", textAlign: "center" }}>
          Leite Vegetal + € 0,30
        </p>
      </div>
    </div>
  );
}

export default CoffeedentialPage;
