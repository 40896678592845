import React from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import menu from './bowl.png';
import delivery from './delivery-man.png';
import reserved from './reserved.png';
import zomato from './zomato.png';
import instagram from './instagram.png';
import facebook from './facebook.png';

const LinksList = () => {
	return (
		<div id='btn-list' className='tc'>
			<Link to="/menu" className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb2 w-75" rel="noopener noreferrer">
				<span id='btn-title' className='pl1 v-mid'>
				<img alt='icon' src={menu} id='btn-icon' className='w-20 bowl' />
				Menu</span>
			</Link>

			<a className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb4 w-75" href="https://www.ubereats.com/pt/store/tjela-restelo/a-9hi2l1Snq_2LDgDvv0-A" target='_blank' rel="noopener noreferrer">
				<img alt='icon' src={delivery} id='btn-icon' className='w-20'/>
				<span id='btn-title' className='pl1 v-mid'>Delivery</span>
			</a>
			
			<a className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb4 w-75" href="tel:+351911967921" target='_blank' rel="noopener noreferrer">
				<img alt='icon' src={reserved} id='btn-icon' className='w-20' />
				<span id='btn-title' className='pl1 v-mid'>Reservas</span>
			</a>

			<a className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb2 w-75" href="http://zoma.to/r/19120430" target='_blank' rel="noopener noreferrer">
				<img alt='icon' src={zomato} id='btn-icon' className='w-20' />
				<span id='btn-title' className='pl1 v-mid'>Zomato</span>
			</a>

			<a className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb2 w-75" href="https://www.instagram.com/tudonatjela/" target='_blank' rel="noopener noreferrer">
				<img alt='icon' src={instagram} id='btn-icon' className='w-20' />
				<span id='btn-title' className='pl1 v-mid'>Instagram</span>
			</a>

			<a className="link grow br3 pv3 ph4 dib bg-white shadow-5 mb3 w-75" href="https://www.facebook.com/tudonatjela/" target='_blank' rel="noopener noreferrer">
				<img alt='icon' src={facebook} id='btn-icon' className='w-20' />
				<span id='btn-title' className='pl1 v-mid'>Facebook</span>
			</a>

			<p id='wifi' className='mb5 w-50 br2 dib'>WIFI: Tjela <br></br> Password: tudonatjela</p>

		</div>
	)	
};

export default LinksList;