import React from 'react';
import { Link } from 'react-router-dom';
import coffee from '../../Assets/coffee-beans.png';

const CoffeeList = () => 
(
		<article className='relative mt5 pt3 center'>
	  
	        <Link to='/menu/cafe/coffeedential' id="coffeedential" className="h5 fl w-100 w-25-l w-50-ns no-underline light-gray link">
	          <h2 className='tc pt4 f3 f2-ns'>Coffeedential</h2>
	        </Link>

	        <Link to='/menu/cafe/filtro' id="filtro" className="h5 fl w-100 w-25-l w-50-ns no-underline light-gray link">
	          <h2 className='tc pt4 f3 f2-ns'>Filtro</h2>
	        </Link>

	         <Link to='/menu/cafe/especiais' id="especiais" className="h5 fl w-100 w-25-l w-50-ns no-underline light-gray link mb4">
	          <h2 className='tc pt4 f3 f2-ns'>Especiais e Frios</h2>
	        </Link>
	       
		  	<div className="flex items-center justify-center shadow-5 ph3 bg-lightest-blue black mb4 w-100">
		        <img src={coffee} height='35px'/>
			        <h1 className="ml4 f3">
			        Sobre o nosso café
				        <br/>
			            <p className='f4 fw6'>
			              Grão 100% Arábica | Café fresco com torras mais claras | Diferentes origens, sabores e métodos de extração.
			            </p>   
			        </h1>
	      	</div>
	  	</article> 
)

export default CoffeeList;