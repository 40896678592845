import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from "./Components/Pages/HomePage";
import Menu from "./Components/Pages/Menu";
import EntradasPage from "./Components/Pages/EntradasPage";
import TjelasPage from "./Components/Pages/TjelasPage";
import SandwishsPage from "./Components/Pages/SandwishsPage";
import BebidasPage from "./Components/Pages/BebidasPage";
import SmootjelasPage from "./Components/Pages/SmootjelasPage";
import DocesPage from "./Components/Pages/DocesPage";
import BrunchPage from "./Components/Pages/BrunchPage";
import OvosTostasPage from "./Components/Pages/OvosTostasPage";
import PanquecasPage from "./Components/Pages/PanquecasPage";
import CoffeePage from "./Components/Pages/CoffeePage";
import CoffeedentialPage from "./Components/Pages/CoffeedentialPage";
import FiltroPage from "./Components/Pages/FiltroPage";
import EspeciaisPage from "./Components/Pages/EspeciaisPage";

import './App.css';
import Admin from './Components/Admin/Admin';


function App() {
  return (
    <div className="App">
      <Route path="/" exact component={HomePage}/>
      <Route path="/menu" exact component={Menu}/>
      <Route path="/entradas" exact component={EntradasPage}/>
      <Route path="/tjelas" exact component={TjelasPage}/>
      <Route path="/sandwishs" exact component={SandwishsPage}/>
      <Route path="/bebidas" exact component={BebidasPage}/>
      <Route path="/smootjelas" exact component={SmootjelasPage}/>
      <Route path="/doces" exact component={DocesPage}/>
      <Route path="/menu/brunch" exact component={BrunchPage}/>
      <Route path="/menu/brunch/ovostostas" exact component={OvosTostasPage}/>
      <Route path="/menu/brunch/panquecas" exact component={PanquecasPage}/>
      <Route path="/menu/cafe" exact component={CoffeePage}/>
      <Route path="/menu/cafe/coffeedential" exact component={CoffeedentialPage}/>
      <Route path="/menu/cafe/filtro" exact component={FiltroPage}/>
      <Route path="/menu/cafe/especiais" exact component={EspeciaisPage} />

	  {/* Admin Page */}
      <Route path="/admin" exact component ={Admin}/>

    </div>
  );
}

export default App;
