import React, { useState, useEffect } from "react";

function Panquecas() {

const [items, setItems] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt6'>
        <div className='db h4 br3 br--top' id='fluffy'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Fluffy Badass</h1>
            </div>
           { items[37] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Panquecas | Bacon | Queijo Cheddar | Ovo Estrelado | Mel | Cebola
            Crocante | Cebolinho
          </p>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='simples'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Simples e Bom</h1>
            </div>
           { items[38] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Panquecas | Manteiga | Mel | Banana
          </p>
        </div>
      </div>

      {/* <div  className="dark-gray center w-80 w-50-l shadow-5 br3 mt4">
     <div className="db h4 br3 br--top" id='berry'>
      </div>
      <div className="pa2 ph3-ns pb3-ns bg-gold br3 br--bottom">
        <div className="dt w-100 mt1">
          <div className="dtc">
            <h1 className="f5 f4-ns mv0 black">Berry Nice</h1>
          </div>
           <div className="dtc tr black">
            <h2 className="f5 mv0">€ 7,00</h2>
          </div>
        </div>
        <p className="f6 lh-copy measure mt2 dark-gray">
        Panquecas | Doce de Leite | Calda de Frutos Vermelhos | Granola Caseira
        </p>
      </div>
    </div>*/}

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='nutjela'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Nutjela</h1>
            </div>
           { items[39] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Panquecas | Nutella | Banana | Bolacha Oreo | Morango | Gelado de
            Baunilha | Côco em Lascas
          </p>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4 mb4'>
        <div className='db h4 br3 br--top' id='acaindoim'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Açaíndoim</h1>
            </div>
           { items[40] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Panquecas | Manteiga de Amendoim | Banana | Mirtilos | Granola | Açaí
          </p>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 mt4'>
        <div className='db h4 br3 br--top' id='atetebabas'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Até te babas</h1>
            </div>
           { items[41] }
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Panquecas | Baba de camelo | Frutos Vermelhos | Bolacha Maria
          </p>
        </div>
      </div>
    </div>
  );
};

export default Panquecas;
