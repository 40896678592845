import React from 'react';
import { Link } from 'react-router-dom';

const MenuList = () => 
(	
	<article className='relative mt5 pt3'>
  
        <Link to='/entradas' id="entradas" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>ENTRADAS</h2>
        </Link>

        <Link to='/tjelas' id="tjelas" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>TJELAS</h2>
        </Link>

        <Link to='/sandwishs' id="sandwishs" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>SAND'WISH'S</h2>
        </Link>

        <Link to='/menu/brunch' id="brunch" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>BRUNCH</h2>
        </Link>

        <Link to='/bebidas' id="bebidas" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>BEBIDAS</h2>
        </Link>

        <Link to='/smootjelas' id="smootjelas" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>SMOOTJELAS & SMOOTHIES</h2>
        </Link>

        <Link to='/doces' id="doces" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>SOBREMESAS</h2>
        </Link>

        <Link to='/menu/cafe' id="cafe" className="h4 h5-ns fl w-100 w-25-l w-50-ns no-underline light-gray link">
          <h2 className='tc pt4 f3 f2-ns'>CAFÉ</h2>
        </Link>          
       
  </article>
)

export default MenuList;


