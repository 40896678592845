import React from "react";

const Doces = () => (
  <div>
    {/* <div  className="dark-gray center w-80 w-50-l mt4 shadow-5 br3 mt6">
      <div className="db h4 br3 br--top" id='cookies'>
      </div>
      <div className="pa2 ph3-ns pb3-ns bg-gold br3 br--bottom">
        <div className="dt w-100 mt1">
          <div className="dtc">
            <h1 className="f5 f4-ns mv0 black">Cookies</h1>
          </div>
          <div className="dtc tr black">
            <h2 className="f5 mv0">€ 2,20</h2>
          </div>
        </div>
      </div>
    </div> */}

    <div className='dark-gray center w-80 w-50-l mt6 shadow-5 br3 mt3 mb4'>
      <div className='db h4 br3 br--top' id='potes'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Doces aos Potes</h1>
          </div>
          <div className='dtc tr black'>
            <h2 className='f5 mv0'>€ 3,00</h2>
          </div>
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Mousse de Oreo | Baba de Camelo | Serradura
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-l mt4 shadow-5 br3 mt3 mb4'>
      <div className='db h4 br3 br--top' id='saladafruta'></div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>É muita fruta</h1>
          </div>
          <div className='dtc tr black'>
            <h2 className='f5 mv0'>€ 3,50</h2>
          </div>
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Manga | Abacaxi | Banana | Morango | Mirtilo | Mel
        </p>
      </div>
    </div>
  </div>
);

export default Doces;
