import React, { useState, useEffect } from "react";

function Bebidas() {

const [items, setItems] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <div className='dark-gray center w-90 shadow-5 br3 mt6'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Limonada</h1>
            </div>
            { items[42] }
          </div>
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Com ou sem açúcar
          </p>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Limonada Maracujá</h1>
            </div>
            { items[43] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Refrigerantes</h1>
            </div>
            { items[44] }
          </div>
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Coca-Cola | Coca-Cola Zero | 7Up | Iced Tea Pêssego
          </p>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Água</h1>
            </div>
            { items[45] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Água das Pedras</h1>
            </div>
            { items[46] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Tjola - Cerveja Mini</h1>
            </div>
            { items[47] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Balde Tjolas (5)</h1>
            </div>
            { items[48] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Cerveja Artesanal MUSA</h1>
            </div>
            { items[49] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Sidra Somersby</h1>
            </div>
            { items[50] }
          </div>
        </div>
      </div>

      {/* <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Lemon Mate</h1>
            </div>
            { items[51] }
          </div>
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Refrigerante Orgânico
          </p>
        </div>
      </div> */}

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Vinho a copo</h1>
            </div>
            { items[52] }
          </div>
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>Branco ou Tinto</p>
        </div>
      </div>
      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Jarro de Vinho 0,5L</h1>
            </div>
            { items[53] }
          </div>
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>Branco ou Tinto</p>
        </div>
      </div>
      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Sangria de Maracujá 1L</h1>
            </div>
            { items[54] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Gin Tónico</h1>
            </div>
            { items[55] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Espresso Martini</h1>
            </div>
            { items[56] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 mb6 shadow-5 br3'>
        <div className='pa2  bg-gold br3'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Caipiroskafé</h1>
            </div>
            { items[57] }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bebidas;
