import React, { useState, useEffect } from "react";

function Sandwishs() {
  const [items, setItems] = useState([]);
  const [menus, setMenus] = useState([]);
  
  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setItems(items => [...items,
          <div className='dtc tr black' key={element[0]}>
          <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
        </div>]);
      });
    });
  }

  async function fetchMenus() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(menu => {
        console.log(menu);
        setMenus(menus => [...menus,
        <span key={menu[0]}>&nbsp; Menu &nbsp; € {menu[2].toFixed(2)}</span>]);
      });
    });
  }
  
  useEffect(() => {
    fetchItems();
    fetchMenus();
  }, []);

  return (
  <div>
    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mt6 mb4'>
      <div className='db h4 br3 br--top' id='burgerlicious'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[24] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>Burgerlicious</h1>
          </div>
          { items[25] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Pão Brioche Artesanal | Hambúrguer de Novilho | Queijo Cheddar |
          Cebola Roxa | Bacon | Maionese de Alho | Chips de Batata Doce
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb4'>
      <div className='db h4 br3 br--top' id='avoca'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[26] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>AvocaCheeseChicken</h1>
          </div>
          { items[27] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Pão Brioche Artesanal | Abacate | Peito de Frango | Queijo Cheddar |
          Bacon | Maionese de Alho | Chips de Batata Doce
        </p>
      </div>
    </div>

    <div className='dark-gray center w-80 w-50-ns mt4 shadow-3 br3 mb5'>
      <div className='db h4 br3 br--top' id='pork'>
        <div className='ribbon ribbon-top-right br2'>
          { menus[28] }
        </div>
      </div>
      <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
        <div className='dt w-100 mt1'>
          <div className='dtc'>
            <h1 className='f5 f4-ns mv0 black'>BBQ Pulled Pork Slaw</h1>
          </div>
          { items[29] }
        </div>
        <p className='f6 lh-copy measure mt2 dark-gray'>
          Pão Brioche Artesanal | Porcp Desfiado | Molho Barbecue | Queijo
          Cheddar | Cole Slaw | Chips de Batata Doce
        </p>
      </div>
    </div>
  </div>
    );
};

export default Sandwishs;
