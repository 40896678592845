import React from 'react';
import Logo from './././logo.jpg';

const Header = () => {
	return (
	<article id='header' className="ph3 ph5-ns tc br2 pt3 mb3 shadow-5">
		<img className="mb2 mt0" alt='logo' id='logo'  src={Logo} />
		<h1 id="title" className="fw6 f3 f2-ns lh-title mt2 mb1 white">
	    	Bem vindo ao TJELA
	  	</h1>
	  	<h2 id='subtitle' className="fw5 f4 lh-copy mt0">
	    	Seleciona uma das opções abaixo
	 	</h2>
	</article>
			)
};

export default Header;