import React, { useState, useEffect } from "react";
import glassIcon from '../../Assets/glass.png';
import bowlIcon from '../../Assets/bowl2.png';

function Smootjelas() {

  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";
  
  const [glass, setGlass] = useState([]);
  const [bowl, setBowl] = useState([]);
  
  async function fetchGlass() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setGlass(glass => [...glass,
          <div className='dtc tr black v-mid' key={element[0]}>
              <h2 className='f5 mv0'>
                <img
                  className='ml2 v-mid'
                  alt=''
                  src={glassIcon}
                  height='35px'
                />{" "}
              € {element[2].toFixed(2)}
              </h2>
            </div>]);
      });
    });
  }

  async function fetchBowl() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        console.log(element);
        setBowl(bowl => [...bowl,
          <div className='dtc tr black v-mid' key={element[0]}>
              <h2 className='f5 mv0'>
                <img
                  className='ml2 v-mid'
                  alt=''
                  src={bowlIcon}
                  height='35px'
                />{" "}
              € {element[2].toFixed(2)}
              </h2>
            </div>]);
      });
    });
  }
  
  useEffect(() => {
    fetchGlass();
    fetchBowl();
  }, []);

  return (
    <div>
      <div className='dark-gray center w-80 w-50-l shadow-5 br3 br--top mt6'>
        <div className='db h4 br3 br--top' id='vermelho'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Berry Nice</h1>
            </div>
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Frutos Vermelhos | Banana | Leite de Amêndoa
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Smoothie</h2>
            { glass[58] }
          </div>
          <div className='dt w-100 mt1 '>
            <h2 className='f5 black'>Smootjela</h2>
            { bowl[59] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 br--top mt4'>
        <div className='db h4 br3 br--top' id='verde'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Verdetox</h1>
            </div>
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Espinafres | Abacaxi | Banana | Leite de Côco
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Smoothie</h2>
            { glass[60] }
          </div>
        </div>
      </div>

      <div className='dark-gray center w-80 w-50-l shadow-5 br3 br--top mt4 mb2'>
        <div className='db h4 br3 br--top' id='acai'></div>
        <div className='pa2 ph3-ns pb3-ns bg-gold br3 br--bottom'>
          <div className='dt w-100 mt1'>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>Açaí</h1>
            </div>
          </div>
          <p className='f6 lh-copy measure mt2 dark-gray'>
            Açaí | Leite | Banana
          </p>
          <div className='dt w-100 mt1'>
            <h2 className='f5 black'>Smoothie</h2>
            { glass[61] }
          </div>
          <div className='dt w-100 mt1 '>
            <h2 className='f5 black'>Smootjela</h2>
            { bowl[62] }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smootjelas;
