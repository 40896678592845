import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import coffeedential from "./coffeedential.png";
import Logo from "./././logo.jpg";

function EspeciaisPage() {

  const [items, setItems] = useState([]);

  const apiUrl = "https://script.google.com/macros/s/AKfycbwRy2WMc2sPg7Wxp49ZZAn9Rc0sYkwBImGEy_2go7PArTlioiUbX01IF4mkkOzwyUvYJg/exec";

  async function fetchItems() {
    fetch(apiUrl).then(response => response.json()).then(data => {
      data.data.forEach(element => {
        setItems(items => [...items,
          <div className='dt w-100 mt1' key={element[0]}>
            <div className='dtc'>
              <h1 className='f5 f4-ns mv0 black'>{element[1]}</h1>
            </div>
            <div className='dtc tr black'>
              <h2 className='f5 mv0'>€ {element[2].toFixed(2)}</h2>
            </div>
          </div>
        ]);
      });
    });
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <header className='bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0'>
        <Link to='/menu/cafe' className='pa2 mr4'>
          <svg
            className='w1'
            data-icon='chevronLeft'
            viewBox='0 0 32 32'
            style={{ fill: "black" }}>
            <title>chevronLeft icon</title>
            <path d='M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z' />
          </svg>
        </Link>

        <img
          className='center ma0'
          alt='logo'
          id='coffedentialLogo'
          src={coffeedential}
        />

        <img className='right-0' alt='logo' id='logosm' src={Logo} />
      </header>

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt6'>
        <div className='pa2  bg-gold br3'>
          {items[81]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>Limonada | Café</p>
        </div>
      </div>

      {/* <div  className="dark-gray center w-90 mt2 shadow-5 br3 mt2">
      <div className="pa2 bg-gold br3">
        <div className="dt w-100 mt1">
          <div className="dtc">
            <h1 className="f5 f4-ns mv0 black">Cold Brew</h1>
          </div>
          <div className="dtc tr black">
            <h2 className="f5 mv0">€ 4,00</h2>
          </div>
        </div>
        <p className="f6 lh-copy measure mt1 mb1 dark-gray">
          Infusão de Café a frio em 24h
        </p>
      </div>
    </div>*/}

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt2'>
        <div className='pa2 bg-gold br3'>
          {items[82]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Espresso e Água Tónica
          </p>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt2'>
        <div className='pa2 bg-gold br3'>
          {items[83]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Café de Filtro Gelado
          </p>
        </div>
      </div>

      {/*<div  className="dark-gray center w-90 mt2 shadow-5 br3 mt2">
      <div className="pa2 bg-gold br3">
        <div className="dt w-100 mt1">
          <div className="dtc">
            <h1 className="f5 f4-ns mv0 black">Iced Coffee c/ Leite</h1>
          </div>
          <div className="dtc tr black">
            <h2 className="f5 mv0">€ 3,00</h2>
          </div>
        </div>
        <p className="f6 lh-copy measure mt1 mb1 dark-gray">
          Café de Filtro Gelado | Leite 
        </p>
      </div>
    </div>*/}

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt2'>
        <div className='pa2 bg-gold br3'>
          {items[84]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>Espresso Gelado</p>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt2'>
        <div className='pa2 bg-gold br3'>
         {items[85]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Espresso Gelado com Espuma de Leite
          </p>
        </div>
      </div>

      <div className='dark-gray center w-90 mt2 shadow-5 br3 mt2 mb4'>
        <div className='pa2 bg-gold br3'>
          {items[86]}
          <p className='f6 lh-copy measure mt1 mb1 dark-gray'>
            Leite com Chocolate Gelado
          </p>
        </div>
      </div>
    </div>
  );
}

export default EspeciaisPage;
