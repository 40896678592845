import React from 'react';
import Sandwishs from '../Sandwishs/Sandwishs';
import { Link } from 'react-router-dom';
import Logo from './logo.jpg';
import burger from '../../Assets/burger.png';
import cup from '../../Assets/coffee-cup.png';
import glass from '../../Assets/cocktail.png';



const SandwishsPage = () => (
    <div>
      <header className="bg-gold db fixed w-100 pa3 z-1 shadow-5 inline-flex items-center top-0">
          <Link to="/menu" className="pa2 mr4">
              <svg className="w1" data-icon="chevronLeft" viewBox="0 0 32 32" style={{fill: 'black'}}>
                <title>chevronLeft icon</title>
                <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
              </svg>
          </Link>

          <h1 className='center ma0'>Sandwish's</h1>

          <img className="right-0" alt='logo' id='logosm'  src={Logo} />
      </header>
      
      <Sandwishs />

      <div className="items-center justify-center pa2 ph3 tc br2 bg-lightest-blue black mt3 mb4">
        <span className="f3">
        Menu  = &nbsp;
        </span>
        <img src={burger} height='35px' style={{verticalAlign: 'middle'}}/>
        &nbsp; + 
        <img src={glass} height='35px' style={{verticalAlign: 'middle'}}/>
        +&nbsp; 
        <img src={cup} height='25px' style={{verticalAlign: 'middle'}}/>
         <h2 class="fw2 f6 lh-copy mt0 mb3">
         Segunda a Sexta ao Almoço
        </h2>
      </div>

      </div>
)

export default SandwishsPage;