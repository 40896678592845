import React from 'react';
import Header from '../Header/Header.js';
import LinksList from '../LinksList/LinksList.js';


const HomePage = () => (
	<div>
		<Header />
    	<LinksList />
    </div>
)

export default HomePage;
