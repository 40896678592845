import React from 'react';
import { Link } from 'react-router-dom';
import clock from '../../Assets/clock.png'

const BrunchList = () => 
(
		<article className='relative mt5 pt3'>
	  
	        <Link to='/menu/brunch/ovostostas' id="ovostostas" className="h5 fl w-100 no-underline light-gray link">
	          <h2 className='tc pt4 f3 f2-ns'>Ovos & Tostas</h2>
	        </Link>

	        <Link to='/menu/brunch/panquecas' id="panquecas" className="h5 fl w-100 no-underline light-gray link mb4">
	          <h2 className='tc pt4 f3 f2-ns'>Panquecas</h2>
	        </Link>
	       
		  	<div className="flex items-center justify-center shadow-5 pa1 bg-lightest-blue black mb4 w-100">
		        <img src={clock} height='35px'/>
			        <h1 className="ml4 f3">
			        Fins de Semana
				        <br/>
			            <p className='f4 fw6'>
			              das 10h00 às 19h00
			            </p>   
			        </h1>
	      	</div>
	  	</article>
)

export default BrunchList;